<template>
    <div class="sub-container sv_page">
		<!-- 정보입력폼 -->
		<div class="mem_container" v-if="$store.state.profile.name == '로컬'">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title"><span class="mem_info"></span><p>개발가이드</p></div>
				</div>
				<div class="contents-body">
					<div class="Area">
						<div class="Item">
							<table class="tb">
								<colgroup>
									<col width="190">
									<col width="300">
									<col width="217">
								</colgroup>
								<tbody>
									<tr class="cursor" onclick="location.href='/pub/'">
										<th><span>*</span><label>퍼블</label></th>
										<td>/pub/</td>
									</tr>
									<tr class="cursor" v-for="depth1 in $store.state.menu.MENU_GUID" :key="depth1.menuId" @click="$router.move(depth1)">
										<th><span>*</span><label>{{depth1.menuNm}}</label></th>
										<td>{{depth1.menuId}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div class="sub-contents" v-for="item in list" :key="item.id" :ref="item.id">
            <img :src="item.src" :alt="item.conts" :title="item.title"/>
        </div>
    </div>
</template>

<script>

export default {
	data(){
		return {
			list : [
				{id   :'info01',
				 title: '',
				 src  : '/images/service01.png',
				 conts: '업계 최고의 금융IT 아웃소싱 서비스!\n성공적인 프로젝트를 위한 맞춤 인재 솔루션\n성공적인 프로젝트를 위하여 인재평가와 검증 및 관리를 통하여 검증된 IT전문인재들을 기업의 프로젝트와 연결해 드립니다.\n하이프로 인재관리 시스템은 성공적인 프로젝트를 위하여 인재평가와 검증 및 관리를 통하여 검증된 IT전문인재들을 기업의 프로젝트와 연결해주는 온라인 인재관리 플랫폼입니다.'},
				{id   :'info02',
				 title: '',
				 src  : '/images/service02.png',
				 conts: '검증된 프로젝트와 검증된 인재 연결 자율적 경력관리와 계약 체결 시 현금 보상 지속적인 인재 관리로 경쟁력 향상\n\n인적자원 등록 안내\n정보 등록 및 업무협약서 개인정보동의 등 계약서 작성 직무 능력별 수시 보상\n\n인적자원 평가 단계\n경력증빙, NCS직무평가 전문가 인터뷰 등 기술·직무·인성·성과평가\n\n인적자원 공급 단계\n우수인력 사전 검색 및 예약·가계약 지원 지속적인 전문 인력관리'},
				{id   :'info03',
				 title: '',
				 src  : '/images/service03.png',
				 conts: '검증된 IT 전문인재 공급\n기술인재: 기술경력, 직무능력, 실적자료 등록. 기술 및 직무경력 평가 및 보상\n\n수행사: 인재 검색 및 인터뷰 요청. 검증인재 추천·공급 및 보상\n\n1. 사전평가를 거친 전문인재 공급\n기술 경력 자동 계산 및 검증\n직무 능력 수준 평가\n업무수행 성과 평가 등\n다단계 검증과 평가 인력 공급\n\n2. 제휴사의 검증된 우수자원 공유\n제휴사의 검증된 인력 공유 활용\n\n3. 프로엔의 품질보증 지원 활동\n자질 향상을 위한 프로엔 교육 지원\n능력 평가에 따른기술 인증서 발급\n관리자 배정을 통한 꾸준한 품질관리'},
				{id   :'info04',
				 title: '',
				 src  : '/images/service04.png',
				 conts: '서비스소개04'},
				{id   :'info05',
				 title: '',
				 src  : '/images/service05.png',
				 conts: '서비스소개05'},
				{id   :'infoPoint',
				 title: '',
				 src  : '/images/service06.png',
				 conts: '서비스소개06'},
				{id   :'infoSafety',
				 title: '',
				 src  : '/images/service07.png',
				 conts: '서비스소개07'},
				{id   :'infoAS',
				 title: '',
				 src  : '/images/service08.png',
				 conts: '서비스소개08'},
			]
		}
	},
	mounted(){
		if(this.$route.query.target){
			this.$nextTick(() => this.focus(this.$route.query.target));
		}
	},
	methods:{
		focus(target){
			//console.log('focus', target);
			if(target && this.$refs[target]){
				this.$.focus(this.$refs[target]);
			}
		}
	}
}
</script>